<template>
  <Button
    @click="click"
    :icon="icon"
    class="p-button-rounded p-button-text button"
    :style="`color: ${color} !important`"
  />
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "pi pi-check",
    },
    color: {
      type: String,
      default: "#1da4a6",
    },
    click: {
      type: Function,
      default: () => alert("Button"),
    },
  },
};
</script>

<style scoped>
.button {
  width: 1.5rem;
  height: 1.5rem !important;
  box-shadow: none !important;
  color: var(--primary) !important;
}
.button:focus {
  /* color: var(--primary) !important; */
  background-color: var(--primary-ligth) !important;
}
.button:hover {
  /* color: var(--primary) !important; */
  background-color: var(--primary-light) !important;
}
</style>
