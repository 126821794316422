<template>
  <div class="container">
    <MobileTable
      v-model:selection="store.state.document.selectedDocuments"
      :label="$route.name"
      :columns="document.columns"
      :data="store.state.document.documents"
    />
    <DesktopTable
      v-model:selection="store.state.document.selectedDocuments"
      :label="$route.name"
      :columns="document.columns"
      :data="store.state.document.documents"
    />
    <Actions :items="document.actions" />
    <div class="container-form">
      <BasicModal v-model:visible="document.showDialog">
        <template v-slot:dialog>
          <BasicTitle title="Agregar Documento" />
          <BasicSubtitle
            subtitle="Los campos señalados con (*) son obligatorios."
          />
          <div class="container-form">
            <BasicLabel label="Categorías *" />
            <div class="select-editable">
              <BorderEditSelect
                v-model="document.data.categoryId"
                label="Categorías *"
                :options="store.state.document.categories"
              />
              <SelectIconButton
                v-if="document.showNewCategory"
                icon="pi pi-check"
                label=""
                :click="onAddCategory"
              />
              <SelectIconButton
                v-else
                icon="pi pi-plus"
                label=""
                :click="() => (document.showNewCategory = true)"
              />
              <SelectIconButton
                icon="pi pi-trash"
                :disabled="!document.data.categoryId"
                label=""
                color="#d32f2f"
                :click="onDeleteCategory"
              />
            </div>
            <BorderInput
              v-if="document.showNewCategory"
              v-model="document.data.categoryName"
              label="Nueva Categoría"
            />
            <FormError :show="document.rules.categoryId" />
            <BorderFile
              :v-model="document.selectedDocuments"
              @select="onSelectFiles"
            />
            <FormError />
            <PrimaryButton label="Guardar" :click="onSave" />
          </div>
        </template>
      </BasicModal>
    </div>
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import BasicModal from "../../widgets/modal/BasicModal.vue";
import BasicTitle from "../../widgets/title/BasicTitle.vue";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle.vue";
import BasicLabel from "../../widgets/label/BasicLabel.vue";
import BorderInput from "../../widgets/input/BorderInput.vue";
import BorderEditSelect from "../../widgets/select/BorderEditSelect.vue";
import FormError from "../../widgets/error/FormError.vue";
import BorderFile from "../../widgets/file/BorderFile.vue";
import PrimaryButton from "../../widgets/button/PrimaryButton.vue";
import SelectIconButton from "../../widgets/button/SelectIconButton.vue";
import { onBeforeMount, reactive, watchEffect } from "vue";
import store from "../../store";
import { actions, aws, validation } from "../../constants";

export default {
  components: {
    MobileTable,
    DesktopTable,
    Actions,
    BasicModal,
    BasicTitle,
    BasicSubtitle,
    BasicLabel,
    BorderInput,
    FormError,
    PrimaryButton,
    SelectIconButton,
    BorderEditSelect,
    BorderFile,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const onDownload = async () => {
      if (store.state.document.selectedDocuments.length !== 1) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe seleccionar un registro!",
          life: 5000,
        });
      } else {
        store.commit("setLoading", true);
        const doc = store.state.document.selectedDocuments[0];
        const downloaded = () => {
          store.commit("setSelectedDocuments", []);
          store.commit("setLoading", false);
        };
        aws.downloadFile(`${doc.name}${doc.ext}`, doc.link, downloaded);
      }
    };

    const document = reactive({
      selectedDocuments: [],
      showNewCategory: false,
      showDialog: false,
      data: {
        categoryId: "",
        categoryName: "",
      },
      rules: {
        categoryId: false,
      },
      columns: [
        {
          field: "name",
          header: "Nombre",
          sort: true,
        },
        {
          field: "documentType",
          header: "Categoría",
          sort: true,
        },
        {
          field: "updatedAt",
          header: "Última Fecha de Carga",
          sort: true,
          date: true,
        },
        {
          field: "updatedUser",
          header: "Cargado por Usuario",
          sort: true,
        },
      ],
      actions: [
        {
          name: "Descargar",
          action: onDownload,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/documentos/listado"),
        },
        {
          name: "Agregar",
          action: () => (document.showDialog = true),
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/documentos/listado"),
        },
      ],
    });

    const onSelectFiles = (ev) => {
      document.selectedDocuments = ev.files;
    };

    const validate = () => {
      let valid = true;

      if (!document.data.categoryId) {
        document.rules.categoryId = true;
        valid = false;
      }

      return valid;
    };

    const onSave = async () => {
      if (!validate()) return;

      store.commit("setLoading", true);

      const category = store.state.document.categories.find(
        (item) => item.id === document.data.categoryId
      );

      const responsePath = await store.dispatch(
        actions.documentActions.getPath,
        {
          buildingId: store.state.general.selectedBuilding,
          folderName: "Otros documentos",
          documentTypeName: category.name,
        }
      );

      if (responsePath.ok) {
        let count = 0;
        const documents = [];
        const { length } = document.selectedDocuments;
        const finish = async () => {
          const request = {
            documents,
          };

          const response = await store.dispatch(
            actions.documentActions.upload,
            request
          );
          if (response.ok) {
            await store.dispatch(actions.documentActions.findAll, {
              buildingId: store.state.general.selectedBuilding,
            });
            store.commit("setLoading", false);
            document.showDialog = false;
          } else {
            store.commit("setLoading", false);
            alert(response.message);
          }
        };

        if (count === length) finish();

        document.selectedDocuments.forEach(async (doc) => {
          const name = doc.name;
          const key = `${responsePath.path}${name}`;
          const uploaded = async () => {
            documents.push({
              path: responsePath.path,
              name: name
                .split(".")
                .slice(0, -1)
                .join("."),
              ext: validation.extensionRegex.exec(name)[0],
              documentTypeId: document.data.categoryId,
              buildingId: store.state.general.selectedBuilding,
            });

            count += 1;
            if (count === length) finish();
          };
          aws.uploadFile(key, doc, uploaded);
        });
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: responsePath.message,
          life: 5000,
        });
      }
    };

    const onAddCategory = async () => {
      store.commit("setLoading", true);
      const response = await store.dispatch(
        actions.documentActions.createCategory,
        {
          buildingId: store.state.general.selectedBuilding,
          name: document.data.categoryName,
        }
      );
      if (response.ok) {
        await store.dispatch(actions.documentActions.categories, {
          buildingId: store.state.general.selectedBuilding,
        });
        store.commit("setLoading", false);
      } else {
        store.commit("setLoading", false);
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
      document.data.categoryId = response.documentTypeId;
      document.data.categoryName = "";
      document.showNewCategory = false;
    };

    const onDeleteCategory = async () => {
      if (!document.data.categoryId) return;
      store.commit("setLoading", true);
      const response = await store.dispatch(
        actions.documentActions.deleteCategory,
        document.data.categoryId
      );
      if (response.ok) {
        await store.dispatch(actions.documentActions.categories, {
          buildingId: store.state.general.selectedBuilding,
        });
        store.commit("setLoading", false);
      } else {
        store.commit("setLoading", false);
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedDocuments", []);
      const finded = store.state.general.breadcrumbs.find(
        (item) => item.to === route.path
      );
      if (!finded)
        store.commit("addBreadcrumb", { label: route.name, to: route.path });

      await store.dispatch(actions.documentActions.findAll, {
        buildingId: store.state.general.selectedBuilding,
      });
      await store.dispatch(actions.documentActions.categories, {
        buildingId: store.state.general.selectedBuilding,
      });
      store.commit("setLoading", false);
    });

    watchEffect(() => {
      if (document.data.categoryId) document.rules.categoryId = false;
    });

    return {
      store,
      document,
      onSave,
      onSelectFiles,
      onDeleteCategory,
      onAddCategory,
    };
  },
};
</script>

<style scoped>
.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
.select-editable {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
